interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  [key: string]: any;
}
export default function Button({
  active,
  text,
  className,
  logoIcon,
  ...rest
}: Props): JSX.Element {
  return (
    <button
      {...rest}
      className={` ${
        active && active
      } flex items-center px-[16px] py-[16px] justify-center transition duration-500 ease-in-out
      whitespace-nowrap outline-none cursor-pointer ${className}`}
    >
      {logoIcon && logoIcon}
      {text}
    </button>
  );
}
