import { Link, NavLink, useNavigate } from "react-router-dom";
import { logo, logoText } from "../../assets/images";
import Button from "../../components/Button";
import { AnimatePresence } from "framer-motion";
import { AppEventContext } from "../../context/event/appEventContext";
import { useContext } from "react";
import HamburgerMenuModal from "./HamburgerMenuModal";
import { MenuIcon } from "../../assets/icons";
const activeLink =
  "text-[16px] leading-[22px] font-[700] p-[8px] flex gap-x-4 items-center whitespace-nowrap text-primary";
const normalLink =
  "text-[16px] leading-[22px] font-[700] flex gap-x-4 items-center hover:text-primary whitespace-nowrap p-[8px] rounded-[16px] text-gray-45 transition duration-500 ease-in-out";
export default function NonAuthNavbar(): JSX.Element {
  const { showModal, setShowModalHandler } = useContext(AppEventContext);
  const navigate = useNavigate();
  return (
    <>
      <div className="sm:h-[80px] h-[60px] top-0 left-0 right-0 fixed z-[100]  ">
        <div className={` xxl:max-w-[1728px] mx-auto h-full bg-bg_black/70`}>
          <div className={`px-4 sm:px-12 md:px-28 h-full `}>
            <div className={`h-full relative`}>
              <div
                className={`h-full flex justify-between gap-x-5 md:gap-x-10 items-center`}
              >
                <Link
                  onClick={() => {
                    navigate("/give");
                  }}
                  to="/"
                  className="flex-1"
                >
                  <div className=" gap-x-2 flex items-center ">
                    <>
                      <div className="flex items-center sm:w-[50px] sm:h-[50px] w-[40px] h-[40px]">
                        <img
                          alt=""
                          className="max-w-full max-h-full object-cover"
                          src={logo}
                        />
                      </div>
                      <div className="flex items-center w-[60px]  h-[60px] sm:w-[100px] sm:h-[80px]">
                        <img
                          alt=""
                          className="max-w-full max-h-full object-cover"
                          src={logoText}
                        />
                      </div>
                    </>
                  </div>
                </Link>
                <nav className={`sm:flex gap-x-2 md:gap-x-4 hidden ml-auto`}>
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    HOME
                  </NavLink>
                  <NavLink
                    to="/live"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    LIVE
                  </NavLink>
                  <NavLink
                    to="/about-us"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    ABOUT US
                  </NavLink>
                </nav>
                <Button
                  onClick={() => navigate("/give")}
                  type="button"
                  className={`bg-transparent text-primary border-[2px] border-primary
            hidden sm:flex outline-none h-[36px] sm:h-[42px]`}
                  text={
                    <p className="font-[700] text-[14px] sm:text-[16px] ">
                      Give
                    </p>
                  }
                />
              </div>
              <MenuIcon
                onClick={() => {
                  setShowModalHandler("hamburgerMenuModal");
                }}
                className="sm:hidden inline-block absolute w-[22px] right-1 top-[50%] -translate-y-[50%] h-[22px]"
              />
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {showModal === "hamburgerMenuModal" && <HamburgerMenuModal />}
      </AnimatePresence>
    </>
  );
}
