import Layout from "./Layout";
import { AppEventProvider } from "./context/event/appEventContext";
import "../src/assets/css/global.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./config/useQueryClient";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

export default function App() {
  return (
    <div className="overflow-hidden">
      <QueryClientProvider client={queryClient}>
        <AppEventProvider>
          <Layout />
          <ToastContainer />
        </AppEventProvider>
      </QueryClientProvider>
    </div>
  );
}
