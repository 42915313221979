import ModalCaseSwingLeft from "../../components/Modals/ModalCaseSwingLeft";
import HamburgerMenu from "./HamburgerMenu";

const HamburgerMenuModal = () => {
  return (
    <>
      <ModalCaseSwingLeft
        className="px-4 sm:px-8 py-4 sm:py-20 bg-white text-black
          ml-auto w-[86%] sm:w-[716px] h-full flex justify-center  gap-y-4 flex-col items-center relative"
      >
        <HamburgerMenu />
      </ModalCaseSwingLeft>
    </>
  );
};

export default HamburgerMenuModal;
