import React from "react";
import { createContext } from "react";
import useContextStateAndActions from "./useContextStateAndActions";

type AppEventContextType = ReturnType<typeof useContextStateAndActions>;

export const AppEventContext = createContext({} as AppEventContextType);

export const AppEventProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <AppEventContext.Provider value={useContextStateAndActions()}>
      {children}
    </AppEventContext.Provider>
  );
};
