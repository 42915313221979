import { NavLink, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { hamburgerMenu } from "../../constants";
import Button from "../../components/Button";
import { useContext } from "react";
import { AppEventContext } from "../../context/event/appEventContext";

const activeLink =
  "text-[16px] leading-[22px] font-[700] p-[8px] flex gap-x-4 items-center whitespace-nowrap text-primary group-hover:text-primary";
const normalLink =
  "text-[16px] leading-[22px] font-[700] flex gap-x-4 items-center whitespace-nowrap p-[8px] rounded-[16px] group-hover:text-black text-gray-45 transition duration-500 ease-in-out";

export default function HamburgerMenu() {
  const navigate = useNavigate();
  const { setShowModalHandler } = useContext(AppEventContext);

  return (
    <>
      {hamburgerMenu.map(({ menu, path }, index) => {
        return (
          <motion.div
            key={index}
            transition={{
              type: "tween",
              duration: 0.5,
              ease: "easeIn",
            }}
            className="py-2"
          >
            <div className="flex justify-between">
              <NavLink
                onClick={() => setShowModalHandler("")}
                to={path}
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                {typeof menu === "string" ? menu : null}
              </NavLink>
            </div>
          </motion.div>
        );
      })}

      <Button
        onClick={() => {
          setShowModalHandler("");
          navigate("/give");
        }}
        type="button"
        className={` text-white bg-primary
            max-w-[150px] sm:max-w-[200px] mx-auto w-full h-[36px] sm:h-[42px] rounded-[25px] mt-10 outline-none
            border-white `}
        text={<p className="font-[700] text-[14px] sm:text-[16px] ">Give</p>}
      />
    </>
  );
}
