import { useCallback, useState } from "react";
import { ShowModal, mode } from "../../types";

export default function useContextStateAndActions() {
  const [mode, setMode] = useState<mode>("false");
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState<ShowModal>("");

  const toggleTheme = useCallback((mode: mode) => {
    if (mode === "false") {
      setMode("true");
      localStorage.setItem("mode", "true");
    } else {
      setMode("false");
      localStorage.setItem("mode", "false");
    }
  }, []);

  const setShowModalHandler = useCallback(
    (action: ShowModal) => setShowModal(action),
    []
  );
  const setScreenSizeHandler = useCallback(
    (value: number) => setScreenSize(value),
    []
  );
  const setModeHandler = useCallback((action: mode) => setMode(action), []);
  return {
    screenSize,
    setScreenSizeHandler,
    toggleTheme,
    mode,
    setModeHandler,
    setShowModalHandler,
    showModal,
  };
}
