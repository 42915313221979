import { ComponentProps, ReactNode, useContext, useRef } from "react";

import { AppEventContext } from "../../context/event/appEventContext";
import { useOnClickOutside } from "../../Hooks/useOnClickOutside";
import { motion } from "framer-motion";

interface Props extends ComponentProps<"div"> {
  children: ReactNode;
}
export default function ModalCaseSwingLeft({ children, className }: Props) {
  const clickRef = useRef<HTMLDivElement>(null);
  const { setShowModalHandler } = useContext(AppEventContext);
  useOnClickOutside(clickRef, () => setShowModalHandler(""));

  return (
    <motion.div
      exit={{ opacity: 0 }}
      initial={{ opacity: 0, x: "100vw" }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4 }}
      className={`fixed inset-0 backdrop-filter backdrop-blur-sm backdrop-saturate-50 z-[10000] overflow-scroll`}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
        ref={clickRef}
        className={className}
      >
        {children}
      </motion.div>
    </motion.div>
  );
}
