import { lazy } from "react";
import { WithSuspense } from "../components/Suspense/WithSuspense";
import { Navigate, useRoutes } from "react-router-dom";

export type AppRoute = {
  path: string;
  element: React.ReactNode;
};

const LandingPage = WithSuspense(lazy(() => import("../Pages/LandingPage")));
const Payment = WithSuspense(lazy(() => import("../Pages/Payment")));
const AboutUsPage = WithSuspense(lazy(() => import("../Pages/AboutUsPage")));
const LivePage = WithSuspense(lazy(() => import("../Pages/LivePage")));

export const Pages = () => {
  const RoutePath: AppRoute[] = [
    { path: "/", element: <LandingPage /> },
    { path: "/give", element: <Payment /> },
    { path: "/about-us", element: <AboutUsPage /> },
    { path: "/live", element: <LivePage /> },
    { path: "*", element: <Navigate to="/" /> },
  ];
  const routes = useRoutes(RoutePath);
  return routes;
};
