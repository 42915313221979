import { NonAuthNavbar } from "./Navbar";
import { Pages } from "../Routes";
import Footer from "./Footer";
import useScrollToTop from "../Hooks/useScrollToTop";
import { useLocation } from "react-router-dom";

export default function Layout() {
  const location = useLocation();

  useScrollToTop();
  return (
    <div className="xxl:max-w-[1728px] mx-auto">
      <NonAuthNavbar />
      <div className="h-[calc(100%-60px)] sm:h-[calc(100%-80px)]">
        <Pages />
      </div>
      {location.pathname !== "/live" && <Footer />}
    </div>
  );
}
