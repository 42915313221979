export const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.5,
      duration: 1,
      ease: "easeOut",
    },
  },
};

export const item = {
  hidden: { opacity: 0, x: "-40px" },
  show: { opacity: 1, x: "0" },
};

export const rotate = {
  show: {
    rotate: 360,
    transition: { duration: 0.5, repeat: Infinity, ease: "linear" },
  },
};
