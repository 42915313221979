import { Link } from "react-router-dom";
import { copyright, facebook, instagram, youtube } from "../../assets/icons";
import { logo, logoText } from "../../assets/images";
const heading3 =
  "font-clashDisplay font-[600] text-[16px] xl:text-[18px] leading-[39px]  mb-4 text-white";
function Footer() {
  return (
    <>
      <div className="bg-bgDark">
        <div className={`py-6 sm:py-12 px-4 sm:px-12 md:px-28`}>
          <div className="flex lg:flex-row gap-y-8 flex-col justify-between text-white">
            <div className="flex-1">
              <Link to="/">
                <div className=" gap-x-2 flex items-center">
                  <div className="flex items-center sm:w-[50px] sm:h-[50px] w-[40px] h-[40px]">
                    <img
                      alt=""
                      className="max-w-full max-h-full object-cover"
                      src={logo}
                    />
                  </div>
                  <div className="flex items-center w-[60px] h-[60px] sm:w-[100px] sm:h-[80px]">
                    <img
                      alt=""
                      className="max-w-full max-h-full object-cover"
                      src={logoText}
                    />
                  </div>
                </div>
              </Link>
              <p className="mt-6 text-[14px] leading-5 font-normal">
                Reach out to us on any of our <br />
                social media networks
              </p>
              <div className="cursor-pointer flex items-center gap-x-4 mt-6">
                <a
                  target="_blank"
                  rel={"noopener noreferrer"}
                  href="https://web.facebook.com/kingsvinehq"
                >
                  <img src={facebook} alt="facebook logo" />
                </a>
                <a
                  target="_blank"
                  rel={"noopener noreferrer"}
                  href="https://www.youtube.com/@revsegunabejide"
                >
                  <img src={youtube} alt="youtube logo" />
                </a>
                <a
                  target="_blank"
                  rel={"noopener noreferrer"}
                  href="https://www.instagram.com/kingsvinehq"
                >
                  <img src={instagram} alt="instagram logo" />
                </a>
              </div>
            </div>
            <div className="flex-1">
              <h3 className={`${heading3}`}>Useful links</h3>
              <ul className="cursor-pointer text-[14px] leading-8 font-normal flex flex-col gap-y-1">
                <Link to="/">
                  <li>Home</li>
                </Link>
                <Link to="/about-us">
                  <li>About Us</li>
                </Link>
                <Link to="/live">
                  <li>Live</li>
                </Link>
                <Link to="/give">
                  <li>Donate</li>
                </Link>
              </ul>
            </div>
            <div className="flex-1">
              <h3 className={`${heading3}`}>Community</h3>
              <ul className="cursor-pointer text-[14px] leading-8 font-normal flex flex-col gap-y-1">
                <li>Blog</li>
                <li>Newsletter</li>
              </ul>
            </div>
            <div className="flex-1">
              <h3 className={`${heading3}`}>Subscribe</h3>
              <div className="relative h-[46px] max-w-[386px]">
                <input
                  className="pl-4 w-full h-full border bg-bgInputBlack outline-none "
                  placeholder="Email"
                />
                <button className="bg-primary text-white text-[14px] font-[500] px-2 absolute max-w-[123px] right-0 top-0 bottom-0">
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black">
        <div className="py-4 px-4 sm:px-12 md:px-28">
          <p className="text-gray-20 flex items-center text-[14px] gap-x-[0.5rem]">
            <img alt="" src={copyright} />
            Copyright 2024. Kingsvine Assembly.
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
