type HamburgerMenuType = {
  [key: string]: string;
  path: string;
};

export const hamburgerMenu: HamburgerMenuType[] = [
  {
    menu: "HOME",
    path: "/",
  },
  {
    menu: "LIVE",
    path: "/live",
  },
  {
    menu: "ABOUT",
    path: "/about-us",
  },
];
