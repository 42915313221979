import { Suspense } from "react";
import { rotate } from "../../lib/framer-motion/animation.framerMotion";
import { motion } from "framer-motion";

export const Skeleton: React.FC = () => {
  return (
    <div className="bg-white/100 inset-0 fixed w-[100vw] z-[100000]">
      <div
        className={`${"flex justify-center items-center"} w-full h-full text-white`}
      >
        <motion.p
          variants={rotate}
          animate="show"
          className={`w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] border-[6px] border-primary border-b-[transparent]
               rounded-[50%] flex mx-auto`}
        />
      </div>
    </div>
  );
};

export const WithSuspense = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<Skeleton />}>
      <Component {...props} />
    </Suspense>
  );
